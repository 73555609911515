<template>
    <div class="field-tabs">
        <v-tabs
        centered
        v-model="tab"
        background-color="primary"
        dark
        >
            <v-tab>Field - {{ field.name }}</v-tab>
            <v-tab>Crop</v-tab>
            <v-tab>Product</v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-title>{{ field.crop }}</v-card-title>
            <v-card-text>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                            <v-text-field
                                v-model="field.acres"
                                label="Acres"
                            ></v-text-field>
                            <v-select
                                :items="tillageItems"
                                label="Tillage"
                                v-model="field.tillage"
                            ></v-select>
                            <v-select
                                :items="drainageItems"
                                label="Drainage"
                                v-model="field.drainage"
                            ></v-select>
                            <v-select
                                :items="irrigationItems"
                                label="Irrigation"
                                v-model="field.irrigation"
                            ></v-select>
                    </v-tab-item>
                    <v-tab-item>
                        <template v-if="field.crop === 'Corn'">
                            <v-row class="yield-environment mb-2">
                                <v-col cols="3">
                                    <p class="mb-0">Yield Environment: </p>
                                    <span>(Select all that apply)</span>
                                </v-col>
                                <v-col class="yield-settings">
                                    <v-row>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Cold"
                                                value="Cold"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Ideal"
                                                value="Ideal"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Salty"
                                                value="Salty"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Tough"
                                                value="Tough"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Variable"
                                                value="Variable"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Wet"
                                                value="Wet"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-select
                                :items="filteredTraits"
                                label="Preferred Trait"
                                v-model="field.trait"
                            ></v-select>
                            <v-select
                                :items="rangeItems"
                                label="Goss's Wilt"
                                v-model="field.gossWilt"
                            ></v-select>
                            <v-select
                                :items="rangeItems"
                                label="Greensnap"
                                v-model="field.greensnap"
                            ></v-select>
                        </template>
                        <template v-else-if="field.crop === 'Peas'">
                            <div>Prior Crop cannot be beans of any type.</div>
                            <v-row class="yield-environment mb-2">
                                <v-col cols="3">
                                    <p class="mb-0">Yield Environment: </p>
                                    <span>(Select all that apply)</span>
                                </v-col>
                                <v-col class="yield-settings">
                                    <v-row>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Cold"
                                                value="Cold"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Ideal"
                                                value="Ideal"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Salty"
                                                value="Salty"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-checkbox
                                                v-model="field.yieldEnvironment"
                                                label="Tough"
                                                value="Tough"
                                                hide-details
                                                multiple
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-select
                                :items="rangeItems"
                                label="White Mold"
                                v-model="field.whiteMold"
                            ></v-select>

                            <v-radio-group v-model="field.rowSpacing">  
                                <template v-slot:label>
                                    <div>Row Spacing</div>
                                </template>
                                <v-radio
                                    v-for="n in rowSpacingOptions"
                                    :key="n"
                                    :label="`${n}`"
                                    :value="n"
                                ></v-radio>
                            </v-radio-group>
                        </template>
                        
                        <template v-else-if="field.crop === 'Spring Wht'">
                            <v-select
                                :items="rangeItems"
                                label="pH"
                                v-model="field.ph"
                            ></v-select>
                            <v-col>
                                <v-slider 
                                    class="pt-2 pr-4"
                                    :max="150"
                                    :min="80"
                                    :step="1"
                                    thumb-label="always"
                                    :thumb-size="40"
                                    label="Yield Target"
                                    v-model="field.yieldTarget"
                                >
                                    <template v-slot:thumb-label="props">
                                        {{ props.value }}
                                    </template>
                                </v-slider>
                            </v-col>
                            <v-col>
                                <v-slider 
                                    class="pt-2 pr-4"
                                    :max="20"
                                    :min="10"
                                    :step="1"
                                    thumb-label="always"
                                    :thumb-size="40"
                                    label="Protein"
                                    v-model="field.protein"
                                >
                                    <template v-slot:thumb-label="props">
                                        {{ props.value }}
                                    </template>
                                </v-slider>
                            </v-col>
                        </template>
                        <template v-else>
                            <v-select
                            :items="soybeanTraits"
                            label="Preferred Trait"
                            v-model="field.trait"
                            ></v-select>
                            <v-select
                                :items="rangeItems"
                                label="IDC"
                                v-model="field.idc"
                            ></v-select>
                            <v-select
                                :items="cystPresent"
                                label="Cyst Present?"
                                v-model="field.cyst"
                            ></v-select>
                            <v-select
                                :items="rangeItems"
                                label="Phytophthora"
                                v-model="field.phytophthora"
                            ></v-select>
                            <v-select
                                :items="rangeItems"
                                label="White Mold"
                                v-model="field.whiteMold"
                            ></v-select>
                        </template>
                    </v-tab-item>
                    <v-tab-item>
                        <v-select
                            :items="brands"
                            label="Brand"
                            v-model="field.brand"
                            @change="emptyProduct"
                        ></v-select>
                        <div v-if="field.crop !== 'Spring Wht'">
                            <v-row align="center" v-if="field.brand === 'Peterson Farms Seed'">
                            <v-col cols="9">
                                <v-select
                                :items="filteredProducts"
                                label="Product"
                                @change="productChange"
                                v-model="field.product"
                                ></v-select>
                            </v-col>
                            <v-col class="text-right" cols="3">
                                <v-btn @click="showAllProducts = !showAllProducts" color="primary">{{ showAllProducts ? 'Filter Products' : 'Show All Products' }}</v-btn>
                            </v-col>
                            </v-row>
                            <v-text-field label="Product" v-else v-model="field.product"></v-text-field>

                        </div>
                        <v-row class="mt-3" v-if="field.crop === 'Soybeans'">
                            <v-col>
                                <v-slider 
                                    class="pt-2 pr-4"
                                    :max="220000"
                                    :min="100000"
                                    :step="1000"
                                    thumb-label="always"
                                    :thumb-size="40"
                                    label="Average Population"
                                    v-model="field.population"
                                >
                                    <template v-slot:thumb-label="props">
                                        {{ props.value / 1000 + 'K' }}
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <v-row class="my-3" align="end" v-else-if="field.crop === 'Corn'">
                            <v-col cols="8" v-if="!field.variableRate">
                                <v-slider 
                                    class="pt-2"
                                    :max="40000"
                                    :min="15000"
                                    :step="1000"
                                    thumb-label="always"
                                    :thumb-size="40"
                                    label="Average Rate"
                                    v-model="field.rate"
                                    hide-details=""
                                >
                                    <template v-slot:thumb-label="props">
                                        {{ props.value / 1000 + 'K' }}
                                    </template>
                                </v-slider>
                            </v-col>
                            <v-col cols="8" v-else>
                                <v-slider 
                                    class="pt-2 pb-4"
                                    :max="12"
                                    :min="3"
                                    :step="1"
                                    thumb-label="always"
                                    :thumb-size="40"
                                    label="Zones"
                                    hide-details=""
                                    v-model="field.zones"
                                    :readonly="field.rateSubmitted"
                                ></v-slider>
                                <v-range-slider 
                                    class="pt-6"
                                    :max="40000"
                                    :min="15000"
                                    :step="1000"
                                    thumb-label="always"
                                    :thumb-size="40"
                                    label="Rate Range"
                                    hide-details=""
                                    v-model="field.rateRange"
                                    :readonly="field.rateSubmitted"
                                >
                                    <template v-slot:thumb-label="props">
                                        {{ props.value / 1000 + 'K' }}
                                    </template>
                                </v-range-slider>
                                <v-row class="pt-6 avg-range" align="end">
                                    <v-col>
                                        <v-subheader class="pa-0 ">Average Rate</v-subheader>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                        outlined
                                        hide-details=""
                                        color="primary"
                                        v-model="field.avgRate"
                                        :readonly="field.rateSubmitted"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="py-0">*Submitting variable rate request is subject to a $2/acre fee.</v-col>
                                </v-row>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col v-if="!field.variableRate">
                                <v-btn dark class="pa-5" color="#015a9c" @click="requestRate('start')">Request Variable<br>Rate Program</v-btn>
                            </v-col>
                            <v-col v-else>
                                <v-btn v-if="field.rateSubmitted" dark class="pa-5" color="#ff0000" @click="requestRate('cancel')">Cancel Variable Rate</v-btn>
                                <v-btn v-else dark class="pa-5" color="#048752" @click="requestRate('confirm')">Submit Variable Rate</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="traverse(false)" color="primary">Back</v-btn>
                <v-btn v-if="tab === 2" @click="traverse(true)" color="primary">Next Field</v-btn>
                <v-btn v-else @click="traverse(true)" color="primary">Next</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { off } from "process";
import { isFulfilled } from "q";
import { mapState, mapGetters } from "vuex";
export default {
    name: "FieldTabs",
    data() {
        return {
            tab: 0,
            showAllProducts: false,
            rowSpacingOptions: [
                "< 12",
                "> 12"
            ],
            rangeItems: [
                "Low",
                "Average",
                "High"
            ],
            tillageItems: [
                "Conventional",
                "Minimal",
                "No-Till"
            ],
            drainageItems: [
                "Good",
                "Average",
                "Poor",
                "Tile"
            ],
            irrigationItems: [
                "Yes",
                "No"
            ],
            fieldTab: "",
            cropTab: "",
            productTab: "",
            yieldEnvironment: [],
            cystPresent: [
                "Yes",
                "No"
            ],
            variableRate: false
        }
    },
    props: ["field"],
    async created() {

    },
    watch: {
        field: {
            deep: true,
            handler(val) {
                console.log('the field changed??');
            }
        }
    },
    computed: {
        ...mapState("product", ["products", "cropValues", "fieldValues, archivedProducts"]),
        ...mapState("plan", ["plan"]),
        ...mapGetters("product", ["cornProducts", "soybeanProducts", "cornTraits", "peaProducts", "soybeanTraits", "brands", "archivedCornProducts", "archivedSoybeanProducts"]),
        filteredProducts() {
            if (this.field.crop === "Corn") {
                this.cornProducts.filter(item => item.pfs_showonwebportalorders === 229170000);
                if (this.showAllProducts) {
                    if(this.field.product && this.field.brand == "Peterson Farms Seed") { 
                        let foundInArchived = this.archivedCornProducts.filter(item => item.name === this.field.product);
                        if(foundInArchived[0]) { 
                            return this.cornProducts.map(item => item.name)
                        } else {
                            return this.cornProducts.filter(item => item.pfs_showonwebportalorders === 229170000).map(item => item.name)
                        }
                    }
                    return this.cornProducts.filter(item => item.pfs_showonwebportalorders === 229170000).map(item => item.name)

                } else {
                    if(this.field.product && this.field.brand == "Peterson Farms Seed") {
                        let foundInArchived = this.archivedCornProducts.filter(item => item.name === this.field.product);
                        if(foundInArchived[0]) {
                            this.cornProducts.push(foundInArchived[0]);
                            return this.cornProducts.filter(item => item.pfs_trait === this.field.trait).sort((a,b) => a.pfs_maturity < b.pfs_maturity ? 1 : a.pfs_maturity > b.pfs_maturity ? -1 : 0).map(item => item.name)
                        } else {
                            return this.cornProducts.filter(item => item.pfs_showonwebportalorders === 229170000 && item.pfs_trait === this.field.trait).sort((a,b) => a.pfs_maturity < b.pfs_maturity ? 1 : a.pfs_maturity > b.pfs_maturity ? -1 : 0).map(item => item.name);
                        }
                    }
                    return this.cornProducts.filter(item => item.pfs_trait === this.field.trait && item.pfs_showonwebportalorders === 229170000).sort((a,b) => a.pfs_maturity < b.pfs_maturity ? 1 : a.pfs_maturity > b.pfs_maturity ? -1 : 0).map(item => item.name)
                }
            }
            else if (this.field.crop === "Soybeans") {
                this.soybeanProducts.filter(item => item.pfs_showonwebportalorders === 229170000)
                if (this.showAllProducts) {
                    if(this.field.product && this.field.brand == "Peterson Farms Seed") {
                        let foundInArchived = this.archivedSoybeanProducts.filter(item => item.name === this.field.product);
                        if(foundInArchived[0]) { 
                            return this.soybeanProducts.map(item => item.name)
                        } else {
                            return this.soybeanProducts.filter(item => item.pfs_showonwebportalorders === 229170000).map(item => item.name)
                        }
                    }
                    return this.soybeanProducts.map(item => item.name && item.pfs_showonwebportalorders === 229170000)
                }
                else {
                    if(this.field.product && this.field.brand == "Peterson Farms Seed") {
                        let foundInArchived = this.archivedSoybeanProducts.filter(item => item.name === this.field.product);
                        if(foundInArchived[0]) {
                            this.soybeanProducts.push(foundInArchived[0]);
                            return this.soybeanProducts.filter(item => item.pfs_trait === this.field.trait).sort((a,b) => a.pfs_maturity < b.pfs_maturity ? 1 : a.pfs_maturity > b.pfs_maturity ? -1 : 0).map(item => item.name)
                        } else {
                            return this.soybeanProducts.filter(item => item.pfs_showonwebportalorders === 229170000 && item.pfs_trait === this.field.trait).sort((a,b) => a.pfs_maturity < b.pfs_maturity ? 1 : a.pfs_maturity > b.pfs_maturity ? -1 : 0).map(item => item.name);
                        }
                    }
                    return this.soybeanProducts.filter(item => item.pfs_trait === this.field.trait && item.pfs_showonwebportalorders === 229170000).sort((a,b) => a.pfs_maturity < b.pfs_maturity ? 1 : a.pfs_maturity > b.pfs_maturity ? -1 : 0).map(item => item.name)
                }
            }
            else if (this.field.crop === "Peas") {
                return this.peaProducts;
            }
            else return this.products
        },
        filteredTraits() {
            let traits = [...this.cornTraits];
            if( this.field.trait && !this.cornTraits.includes(this.field.trait)) {
                traits.push(this.field.trait)
                return traits;
            } else {
                return [...this.cornTraits];
            }
        }
    },
    methods: {
        async requestRate(phase) {
            if (phase === "start") {
                if (!this.field.rateRange) {
                    this.field.rateRange =  [20000, 30000]
                    this.field.avgRate = 20000
                }
                this.field.variableRate = true
            }
            else if (phase === 'cancel') {
                this.field.rateSubmitted = false
                this.field.variableRate = false
            }
            else {
                this.field.variableRate = true
                this.field.rateSubmitted = true
                let res = await this.$store.dispatch("plan/sendRateEmail", JSON.stringify({
                    url: `${this.$route.params.id}`,
                    dealer: this.plan.customer.dealerName,
                    customer: this.plan.customer.crmName,
                    plan: this.plan.name,
                    rep: this.plan.owner.email,
                    field: this.field.name,
                    zones: this.field.zones,
                    rateRange: this.field.rateRange,
                    avgRate: this.field.avgRate
                }))
                if (res) {
                    this.$swal({
                    toast: true,
                    icon: "success",
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 4000,
                    title: "Email sent",
                    timerProgressBar: true
                    })
                }
                else {
                    this.$swal({
                    toast: true,
                    icon: "error",
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 4000,
                    title: "Error sending email",
                    timerProgressBar: true
                    })
                }
            }
            
            // this.variableRate = !this.variableRate
            // this.field.variableRate = !this.field.variableRate
            // return
            // this.$swal({
            //     title: '<strong>REQUEST VARIABLE RATE</strong>',
            //     html: '<p>1</p><p>2</p><p>3</p>',
            //     showCancelButton: true,
            //     confirmButtonColor: '#015a9c',
            //     // cancelButtonColor: '#fff',
            //     confirmButtonText: 'SUBMIT REQUEST',
            //     cancelButtonText: 'CANCEL',
            //     focusCancel: true,
            //     allowOutsideClick: false
            // })
            // .then(async (result) => {
            //     if (result.isConfirmed) {
            //         let fields = await this.$store.dispatch("plan/getGrowerFields", this.customer.pfs_farmmarketid)
            //         await this.$store.dispatch("product/getCRMProducts")
            //         this.setFields(fields)
            //     }
            // })
        },
        emptyProduct(val) {
            if (val === 'Peterson Farms Seed') {
                this.field.product = this.filteredProducts[0]
            }
            else {
                this.field.product = ""
            }
        },
        productChange(val) {
            let product = this.products.find(item => item.name === val)
            if(product) {
                this.field.trait = product.pfs_trait
            }
        },
        traverse(direction) {
            if (direction && this.tab === 2) {
                if (this.field.brand !== "Peterson Farms Seed") {
                    this.$swal({
                        title: '<strong>YOU SURE?</strong>',
                        customClass: "farm-market",
                        showCancelButton: true,
                        confirmButtonColor: '#015a9c',
                        cancelButtonColor: '#757575',
                        confirmButtonText: 'YEAH, I GUESS SO',
                        cancelButtonText: 'JUST KIDDING',
                        // focusCancel: true,
                        allowOutsideClick: false
                    })
                    .then(async (result) => {
                        if (result.isConfirmed) {
                            this.tab = 0
                            this.showAllProducts = false
                            this.$emit("done", this.field.name)
                        }
                    })
                }
                else {
                    this.tab = 0
                    this.showAllProducts = false
                    this.$emit("done", this.field.name)
                }
            }
            else if (direction) {
                if (this.tab === 1 && !this.field.product) {
                    // default select product
                    // this.field.product = this.filteredProducts[0]
                }
                this.tab++
            }
            else if (!direction && this.tab === 0) {
                this.showAllProducts = false
                this.$emit("exit")
            }
            else if (!direction) {
                this.tab--
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-card__text {
        min-height: 300px;
    }
    .v-card__title{
        color: #015a9c;
    }
    .yield-settings{
        .col {
            padding: 0;
            .v-input{
                padding-top: 0;
                ::v-deep .v-label{
                    font-size: 13px;
                }
            }
        }
    }
    .yield-environment{
        span {
            color: #000;
            font-size: .75rem;
        }
    }
    .rate {
        color: #015a9c;
    }
    .avg-range{
        .col:first-child {
            flex-grow: 0;
        }
        .v-subheader{
            font-size: 1rem;
            white-space: nowrap;
        }
    }
</style>