<template>
    <v-card
        light
        class="new-plan field-settings"
    >
            <v-card-title class="primary">
                <div>{{ planName ? planName : planYear }}</div>
                <v-btn :to="`/customers/${customer.id}`" class="customer-info" dark text plain>{{ customer.crmName ? customer.crmName : 'Customer' }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mr-2" :dark="add" small @click="$emit('add')">Add</v-btn>
                <v-btn class="mr-2" :dark="draw" small @click="$emit('draw')">Draw</v-btn>
                <v-btn class="mr-2" :dark="select" small @click="$emit('updateSelect')">Select</v-btn>
                <v-btn class="mr-2" small @click="combineFields">Combine</v-btn>
                <v-btn class="mr-2" small @click="deleteFields">Delete</v-btn>
                <v-btn small @click="reset">Undo</v-btn>
            </v-card-title>
            <v-card-text class="px-0">
                <v-data-table
                    :headers="fieldHeaders"
                    :items="fields"
                    @update:options="handleChangeTableOptions"
                    :items-per-page="rowsPerPage"
                    class="elevation-1"
                    item-class="field"
                    show-select
                    :page.sync="page"
                    item-key="fieldId"
                    v-model="fieldsSelected"
                    no-data-text="No fields available"
                >
                    <template v-slot:item.name="props">
                    <v-edit-dialog
                    >
                    {{ props.item.name }}
                    <template v-slot:input>
                        <v-text-field
                        @focus="$event.target.select()"
                        v-model="props.item.name"
                        label="Edit"
                        single-line
                        counter
                        @change="nameChange(props.item, $event)"
                        ></v-text-field>
                    </template>
                    </v-edit-dialog>
                    </template>
                    <template v-slot:item.crop="props">
                        <v-select
                        @click.stop=""
                        v-model="props.item.crop"
                        :items="cropTypes"
                        :item-text="cropTypes.text"
                        :item-value="cropTypes.value"
                        :class="cropSelect"
                        solo
                        hide-details
                        dense
                        @change="cropChange(props.item)"
                        ></v-select>
                    </template>
                    <template v-slot:item.acres="props">{{ Math.round(props.item.acres * 100) / 100 }}</template>
                    <template v-slot:item.edit="props">
                        <v-btn
                        @click.stop="magnify(props.item)"
                        icon
                        >
                        <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn v-if="props.item.crop === 'Corn' || props.item.crop === 'Soybeans' || props.item.crop === 'Peas' || props.item.crop === 'Spring Wht'"
                        @click.stop="edit(props.item)"
                        icon
                        >
                        <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        </div>
                    </template>
                    <template v-slot:item.done="props">
                        <div  v-if="props.item.done"><v-icon color="green">mdi-check-bold</v-icon></div>
                    </template>
                </v-data-table>
            </v-card-text>
        <v-card-actions class="pt-0 pr-4 justify-end">
        <v-btn @click="$emit('next')" class="mb-2" color="primary">Next Field</v-btn>
        <v-btn :loading="saveLoading" @click="savePlan" class="mb-2" color="primary">Save</v-btn>
        <v-btn :loading="finishLoading" @click="finishPlan" class="mb-2" color="primary">Finish</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Cookies from 'js-cookie'
export default {

    name: "FieldSettings",
    data() {
        return {
            name: false,
            saveLoading: false,
            finishLoading: false,
            page: 1,
            cropSelect: 'crop-select',
            fieldsSelected: [],
            fieldHeaders: [
                {
                    text: "Field Name",
                    value: "name"
                },
                {
                    text: "Crop",
                    value: "crop",
                    width: "120px"
                },
                {
                text: "Product",
                value: "product"
                },
                {
                text: "Trait",
                value: "trait"
                },
                {
                    text: "Acres",
                    value: "acres"
                },
                {
                    text: "C. Units",
                    value: 'calcUnits'
                },
                {
                    text: "View / Edit",
                    value: "edit",
                    sortable: false,
                    // align: "center"
                },
                {
                    text: "Completed",
                    value: "done",
                    align: "center"
                }
            ],
            cropTypes: [
                {
                    text: "Soybeans",
                    value: "Soybeans"
                },
                {
                    text: "Corn",
                    value: "Corn"
                },
                {
                    text: "Wheat",
                    value: "Spring Wht"
                },
                {
                    text: "Canola",
                    value: "Canola"
                },
                {
                    text: "Sunflowers",
                    value: "Sunflowers"
                },
                {
                    text: "Hay",
                    value: "Hay"
                },
                {
                    text: "Barley",
                    value: "Barley"
                },
                {
                    text: "Pasture",
                    value: "Pasture"
                },
                {
                    text: "Herb Wetland",
                    value: "Herbwetland"
                },
                {
                    text: "Wood Wetland",
                    value: "Woodwetland"
                },
                {
                    text: "Alfalfa",
                    value: "Alfalfa"
                },
                {
                    text: "Sugar Beets",
                    value: "Sugar Beets"
                },
                {
                    text: "Beans",
                    value: "Beans"
                },
                {
                    text: "Forestry",
                    value: "Forestry"
                },
                {
                    text: "Peas",
                    value: "Peas"
                },
                {
                    text: "Other",
                    value: "Other"
                },
                // {
                //     text: "",
                //     value: ""
                // }
            ]
        }
    },
    props: ["fields", "planYear", "planName", "select", "draw", "add", "customer"],
    async created() {
        if(Cookies.get('itemsPerPageEdit')) {
            this.rowsPerPage = parseInt(Cookies.get('itemsPerPageEdit'));
        } else {
            this.rowsPerPage = 10;
        }
        console.log(this.fields);
    },
    computed: {
        ...mapGetters("product", ["cornTraits", "soybeanTraits"])
    },
    methods: {
        handleChangeTableOptions (val) {
            let newItemsPerPage = val.itemsPerPage;
            Cookies.set('itemsPerPageEdit', newItemsPerPage);
        },
        savePlan() {
            this.saveLoading = true
            this.$emit('save')
        },
        finishPlan() {
            this.finishLoading = true
            this.$emit('finish')
        },
        nameChange(item) {
            this.$emit("nameChange", item)
        },
        combineFields() {
            if (this.fieldsSelected.length > 1) {
                this.$emit("combine", this.fieldsSelected)
                this.fieldsSelected = []
                this.page = 1
            }
        },
        cropChange(val) {
            this.$emit("cropChange", val)
            if (val.crop === "Corn") {
                    val.cropSettings = {
                        trait: this.cornTraits[0],
                        gossWilt: "Average",
                        greensnap: "Low",
                        yieldEnvironment: [],
                        rate: 28
                    }
                }
                else if (val.crop === "Soybeans") {
                    val.cropSettings = {
                        trait: this.soybeanTraits[0],
                        idc: "Average",
                        cyst: "No",
                        phytophthora: "Average",
                        whiteMold: "Low"
                    }
                }
        },
        edit(field) {
            this.$emit("editField", field)
        },
        deleteFields() {
            this.fieldsSelected.forEach(item => this.$emit("deleteField", item))
            this.fieldsSelected = []
        },
        magnify(field) {
            this.$emit("magnify", field)
        },
        reset () {
            this.$swal({
                title: '<strong>ARE YOU SURE YOU WANT TO RESET ALL FIELDS TO DEFAULT?</strong>',
                customClass: "farm-market",
                showCancelButton: true,
                confirmButtonColor: '#c41731',
                cancelButtonColor: '#757575',
                confirmButtonText: 'RESET ALL FIELDS',
                cancelButtonText: 'CANCEL',
                allowOutsideClick: false
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    this.$emit("reset")
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .customer-info{
    }
    .new-plan {
        .v-card__title{
            color: #fff;
            text-transform: uppercase;
        }
        // .field {
        //     cursor: pointer;
        //     &:hover{
        //         background-color: #015a9c;
        //     }
        // }
    }
    .field-settings{
        tr td:nth-child(2) {
            width: 22%;
        }
        tr td:nth-child(3) {
            width: 15%;
        }
        tr td:nth-child(5) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        th:nth-child(5) {
            text-align: center !important;
        }
        tr td:nth-child(6) {
            // width: 10%;
        }
    }
</style>