<template>
    <v-card
        light
        max-width="500px"
        class="mx-auto new-plan"
    >
        <v-card-title class="primary">New Plan</v-card-title>
         <v-list class="px-5 pb-0">
          <v-list-item class="pa-0" two-line>
            <v-list-item-content>
              <v-list-item-title class="title">Default Field Setup</v-list-item-title>
              <v-list-item-subtitle class="mt-1">(Select what applies to the MAJORITY of your fields)</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="pa-0">
            <v-list-item-content class="pb-0">
              <v-list-item-title class="title">Tillage:</v-list-item-title>
              <v-radio-group v-model="defaultSettings.tillage" class="pl-3 mt-2">
                <v-radio
                    value="Conventional"
                    label="Conventional"
                ></v-radio>
                <v-radio
                    value="Minimal"
                    label="Minimal"
                ></v-radio>
                <v-radio
                    value="No-Till"
                    label="No-Till"
                ></v-radio>
              </v-radio-group>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="pa-0">
            <v-list-item-content class="pb-0">
              <v-list-item-title class="title">Drainage:</v-list-item-title>
              <v-radio-group v-model="defaultSettings.drainage" class="pl-3 mt-2">
                <v-radio
                    value="Good"
                    label="Good"
                ></v-radio>
                <v-radio
                    value="Average"
                    label="Average"
                ></v-radio>
                <v-radio
                    value="Poor"
                    label="Poor"
                ></v-radio>
                <v-radio
                    value="Tile"
                    label="Tile"
                ></v-radio>
              </v-radio-group>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="pa-0">
            <v-list-item-content class="pb-0">
              <v-list-item-title class="title">Irrigation:</v-list-item-title>
              <v-radio-group v-model="defaultSettings.irrigation" class="pl-3 mt-2">
                <v-radio
                    value="Yes"
                    label="Yes"
                ></v-radio>
                <v-radio
                    value="No"
                    label="No"
                ></v-radio>
              </v-radio-group>
            </v-list-item-content>
          </v-list-item>
         </v-list>
         <v-card-actions class="pt-0 justify-center">
            <v-btn @click="$emit('back')" class="mb-2" color="primary">Back</v-btn>
            <v-btn @click="setFieldValues" class="mb-2" color="primary">Finish</v-btn>
         </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "FieldSetup",
    data() {
        return {
            defaultSettings: {
                tillage: "Conventional",
                drainage: "Average",
                irrigation: "No"
            }
        }
    },
    async created() {
    },
    computed: {
    },
    methods: {
      setFieldValues() {
        this.$store.commit("product/setFieldValues", this.defaultSettings)
        this.$emit('finish')
      }
    }
}
</script>

<style lang="scss">
    .new-plan {
        .v-card__title{
            color: #fff;
            text-transform: uppercase;
            // font-weight: bold;
        }
    }
</style>