<template>
    <div class="new-plan">
        <h1 class="text-center my-3" v-if="customer.farmName">{{ customer.farmName }}</h1>
        <h1 class="text-center my-3" v-else>{{ customer.first }} {{ customer.last }}</h1>
        <Crop-Setup @next="grabCropValues" v-if="step === 1 && customer" />
        <Field-Setup @back="step--" @finish="finish" v-else-if="step === 2 && customer" />
        <v-row v-if="fields.length">
            <v-col>
                <Field-Settings :draw="draw" :add="add" @updateSelect="updateSelect" :select="select" ref="settings" @reset="reset" @combine="combine" @draw="drawField" @add="addField" @next="nextField" @finish="finishSetup" v-if="mapReady && !showTabs" :fields="fields" :planYear="plan.year" :planName="plan.name" @editField="editField" @deleteField="deleteField" @nameChange="nameChange" @cropChange="cropChange" @magnify="magnify"  />
                <Field-Tabs @done="fieldReady" @exit="exit" v-else-if="showTabs" :field="field" />
            </v-col>
            <v-col>
                <Map @prospectCoords="prospectCoords" @pushField="pushField" ref="map" :draw="draw" :add="add" :select="select" @geoJsonReady="mapReady = true" :growerId="customer.pfs_farmmarketid" :mapCoords="mapCoords" @editField="editMapField" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import FieldSetup from "@/components/FieldSetup"
import CropSetup from "@/components/CropSetup"
import Map from "@/components/Map"
import FieldSettings from "@/components/FieldSettings"
import FieldTabs from "@/components/FieldTabs"
export default {
    name: "NewPlan",
    data() {
        return {
            mapCoords: "",
            draw: false,
            add: false,
            startingFields: [],
            select: false,
            dev: false,
            field: "",
            fields: [],
            step: 1,
            mapReady: false,
            showTabs: false,
            planFields: {},
            plan: {
                year: new Date().getFullYear(),
                customerid: "",
                name: ""
            }
        }
    },
    components: {
        FieldSetup,
        CropSetup,
        Map,
        FieldSettings,
        FieldTabs
    },
    async created() {
        if (this.token) {
            await this.$store.dispatch("customer/getCustomerData", this.$route.params.id)
            this.grabPlanFields()
            this.$store.dispatch("product/getCRMProducts")
        }
    },
    watch: {
        async token(val) {
            await this.$store.dispatch("customer/getCustomerData", this.$route.params.id)
            this.grabPlanFields()
            this.$store.dispatch("product/getCRMProducts")
            if (this.customer.plans) {
                this.plans = this.customer.plans
            }
        },
        fields(val) {
            if (!this.startingFields.length) {
                this.startingFields = JSON.parse(JSON.stringify(val))
            }
        },
        async mapReady(val) {
            // if(val) {
            //     await this.savePlan()
            // }
        }
    },
    computed: {
        ...mapState("customer", ["customer"]),
        ...mapState("user", ["token", "user"]),
        ...mapState("product", ["products", "cropValues", "fieldValues"]),
        ...mapGetters("product", ["cornTraits", "soybeanTraits"]),
        remainingFields() {
            return this.fields.filter(item => !item.done && (item.crop === "Corn" || item.crop === "Soybeans"))
        }
    },
    methods: {
        async prospectCoords(val) {
            let [prospect] = await this.$store.dispatch("plan/getGrowerData", {
                lat: val.lat,
                lon: val.lng,
                radius: 1
            })
            if (prospect) {
                let field = prospect.Fields[0].Geo.features[0]
                let fieldVals = JSON.parse(JSON.stringify(this.fieldValues))
                let newField = {
                    name: "new field",
                    brand: "Peterson Farms Seed",
                    done: false,
                    acres: field.properties.acres,
                    drainage: fieldVals.drainage,
                    irrigation: fieldVals.irrigation,
                    tillage: fieldVals.tillage,
                    productSettings: {
                        brand: "Peterson Farms Seed",
                        product: ""
                    },
                    settings: fieldVals
                }
                this.fields.unshift(newField)
                this.$refs.map.addNewField(field)
                this.reIndex()
            }
            else {
                this.$swal({
                    toast: true,
                    icon: "error",
                    customClass: "farm-market-field",
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    title: "No field found",
                    timerProgressBar: true
                })
            }
        },
        updateSelect() {
            this.select = !this.select
            this.add = false
            this.draw = false
        },
        addField() {
            this.add = !this.add
            this.draw = false
            this.select = false
            this.$refs.map.toggleAdd()
        },
        drawField() {
            this.draw = !this.draw
            this.add = false
            this.select = false
            this.$refs.map.toggleDraw()
        },
        pushField(acres) {
            let fieldVals = JSON.parse(JSON.stringify(this.fieldValues))
            let newField = {
                name: "new field",
                brand: "Peterson Farms Seed",
                done: false,
                acres,
                drainage: fieldVals.drainage,
                irrigation: fieldVals.irrigation,
                tillage: fieldVals.tillage,
                productSettings: {
                    brand: "Peterson Farms Seed",
                    product: ""
                },
                settings: fieldVals
            }
            this.fields.unshift(newField)
            this.reIndex()
            // this.draw = false
        },
        checkProp(val, propName, prop1, prop2) {
            return path([propName, prop1, prop2], val) ? val.prop1.prop2 : null
        },
        reset() {
            this.fields = JSON.parse(JSON.stringify(this.startingFields))
            this.$refs.map.reset()
        },
        combine(val) {
            val.forEach(item => {
                let ind = this.fields.findIndex(field => {
                    return field.id === item.id
                })
                this.fields.splice(ind, 1)
            })
            let fieldVals = JSON.parse(JSON.stringify(this.fieldValues))
            let newField = val.reduce((acc, curr) => {
                acc.acres = acc.acres + curr.acres
                return acc
            }, {
                name: (val.map(item => item.name)).join(","),
                done: false,
                acres: 0,
                drainage: fieldVals.drainage,
                irrigation: fieldVals.irrigation,
                tillage: fieldVals.tillage,
                brand: "Peterson Farms Seed",
                productSettings: {
                    brand: "Peterson Farms Seed",
                    product: ""
                },
                settings: JSON.parse(JSON.stringify(this.fieldValues))
            })
            newField.acres = this.$refs.map.combine(val)
            this.fields.unshift(newField)
            this.reIndex()
        },
        exit(val) {
            this.showTabs = false
            this.mapCoords = false
        },
        grabCropValues(val) {
            this.step++
        },
        nextField() {
            let next = this.fields.filter(item => !item.done && (item.crop === "Corn" || item.crop === "Soybeans"))
            if (!next.length) this.showTabs = false
            else this.editField(next[0]) 
        },
        checkFields() {
            if (this.remainingFields.length) return false
            else return true
        },
        checkFieldType(val) {
            let final = (val.toLowerCase()).replace(/ /g, '')
            if (final === "pas/grasoth") {
                return "pasture"
            }
            else if (final === "springwht") {
                return "wheat"
            }
            else if (final === "forestry,herbwetland") {
                return "herbwetland"
            }
            else if (final === "drybeans") {
                return "beans"
            }
            else if (final === "mixedhay") {
                return "hay"
            }
            else if (final === "" || final  === "crp" || final  === "shrubland" || final  === "oranges" || final  === "fallow") {
                return "other"
            }
            else return final
        },
        finalizeField() {
            let fields = JSON.parse(JSON.stringify(this.fields))
            return fields.map((item, ind) => {
                item.geoJson = JSON.stringify(this.$refs.map.customerGeo.features[ind])
                item.crop = this.checkFieldType(item.crop)
                return item
            })
        },
        saveInitialFields(allFields, customerGeo) {
            return allFields.map((item, ind) => {
                item.fieldId = ind
                item.done = false
                item.geoJson = JSON.stringify(customerGeo.features[ind])
                item.crop = this.checkFieldType(item.crop)
                if (item.yieldEnvironment) {
                    if (!item.yieldEnvironment.length) {
                        delete item.yieldEnvironment
                    }
                }
                return item
            })
        },
        async savePlan(fields, mapData) {
            let final = this.saveInitialFields(fields, mapData)
            // let products = final.map(item => item.product)
            let data = {
                name: this.cropValues.name,
                fields: final,
                planCustomerId: this.$route.params.id,
                planOwnerId: this.user.username,
                planGrowerId: this.customer.pfs_farmmarketid,
                year: this.plan.year,
                defaultSettings: JSON.stringify(this.fieldValues),
                cropValues: JSON.stringify(this.cropValues),
                owner: this.user.username,
                dealerId: this.user.dealerId ? this.user.dealerId : this.customer.pfs_dealerid ? this.customer.pfs_dealerid.pfs_dealerid : undefined,
                crmName: this.customer.name
            }
            let planId = await this.$store.dispatch("plan/createFinalPlan", data)
            this.$router.push(`/plans/${planId}/edit`)
        },
        async finishSetup() {
            if (!this.checkFields() && !this.dev) return
            if (this.dev) {
                this.setProducts()
            }
            let final = this.finalizeField()
            let products = final.map(item => item.product)
            let data = {
                name: this.cropValues.name,
                fields: final,
                planCustomerId: this.$route.params.id,
                planGrowerId: this.customer.pfs_farmmarketid,
                year: this.plan.year
            }
            let planId = await this.$store.dispatch("plan/createFinalPlan", data)
            this.$router.push(`/plans/${planId}`)
        },
        fieldReady(fieldName) {
            let field = this.fields.find(item => item.name === fieldName)
            field.done = true
            this.nextField()
            // this.showTabs = false
        },
        async grabPlanFields() {
            if (this.customer.pfs_farmmarketid) {
                let [fields, mapFields] = await Promise.all([this.$store.dispatch("plan/getGrowerFields", this.customer.pfs_farmmarketid), this.$store.dispatch("plan/getGrowerGeoData", this.customer.pfs_farmmarketid)])
                this.planFields = {
                    fields,
                    mapFields
                }
            }
        },
        async finish(val) {
            let finalFields = []
            let mapData = {
                type: "FeatureCollection",
                features: []
            }
            if (Object.keys(this.planFields).length) {
                finalFields = this.setFields(this.planFields.fields, false)
                mapData = this.setData(this.planFields.mapFields)
            }
            this.step++
            await this.savePlan(finalFields, mapData)
        },
        setProducts() {
            this.fields.forEach(item => {
                item.done = true
            })
        },
        setData(data, colorChange = true) {
            // this.customerGeo = null
            this.customerGeo = data
            this.customerGeo.features.forEach((item,ind) => {
                item.properties.fieldId = ind
                item.properties.title = item.properties.title ? item.properties.title : `${ind + 1}`
                if (colorChange) {
                    item.properties.color = item.properties.crop === 'Field Corn' ? "#ffff00" : item.properties.crop === 'Soybeans' ? "#008000" : "#808080"
                }
            })
            return this.customerGeo
        },
        editField(val) {
            let ind = this.fields.findIndex(item => item.id === val.id)
            this.mapCoords = ind
            this.field = val
            this.showTabs = true
        },
        deleteField(val) {
            let ind = this.fields.findIndex(item => item.id === val.id)
            this.fields.splice(ind, 1)
            this.$refs.map.customerGeo.features.splice(ind, 1)
            this.reIndex()
        },
        reIndex() {
            this.fields.forEach((item, key) => {
                item.id = key
            })
            this.$refs.map.customerGeo.features.forEach((item, key) => {
                item.properties.fieldId = key
            })
        },
        findFeature(val) {
            let ind = this.fields.findIndex(item => item.id === val.id)
            return {
                feature: this.$refs.map.customerGeo.features[ind],
                ind
            }
        },
        editCropInfo(item) {
            if (item.crop === "Corn") {
                item.trait = this.cornTraits[0]
                item.gossWilt = "Average"
                item.greensnap = "Low"
                item.yieldEnvironment = []
                item.rate = 32000
                item.variableRate = false
                delete item.idc
                delete item.cyst
                delete item.phytophthora
                delete item.whiteMold
                delete item.population
            }
            else {
                item.trait = this.soybeanTraits[0]
                item.idc = "Average"
                item.cyst = "No"
                item.phytophthora = "Average"
                item.whiteMold = "Low"
                item.population = 140000
                delete item.gossWilt
                delete item.greensnap
                delete item.yieldEnvironment
                delete item.rate
                delete item.variableRate
            }
        },
        cropChange(val) {
            if (val.crop !== "Corn" && val.crop !== "Soybeans") return
            this.editCropInfo(val)
            let { feature, ind } = this.findFeature(val)
            feature.properties.color = val.crop === "Corn" ? "#ffff00" : val.crop === "Soybeans" ? "#008000" : "#808080"
            feature.properties.crop = val.crop === "Corn" ? "Field Corn" : val.crop === "Soybeans" ? "Soybeans" : ""
            this.$refs.map.customerGeo.features.splice(ind, 1, feature)
        },
        nameChange(val) {
            let { feature, ind } = this.findFeature(val)
            feature.properties.title = val.name
            this.$refs.map.customerGeo.features.splice(ind, 1, feature)
        },
        magnify(val) {
            let ind = this.fields.findIndex(item => item.id === val.id)
            this.mapCoords = ind
        },
        checkCrop(val) {
            if (val === "Corn" || val === "Soybeans") return true
            else return false
        },
        editMapField(val) {
            let ind = this.fields.findIndex(item => item.id === val)
            if (this.select) {
                this.$refs.settings.fieldsSelected.push(this.fields[ind])
            }
            else {
                if (this.checkCrop(this.fields[ind].crop)) {
                this.editField(this.fields[ind])
            }
                else {
                    this.showTabs = false
                }
            }
        },
        setFields(fields, set = true) {
                let allFields = fields.map((item,key) => {
                item.id = key
                item.name = key + 1
                item.brand = "Peterson Farms Seed"
                item.product = ""
                item.settings = JSON.parse(JSON.stringify(this.fieldValues))
                item.drainage = item.settings.drainage
                item.irrigation = item.settings.irrigation
                item.tillage = item.settings.tillage
                item.done = false
                if (item.crop === "Field Corn") {
                    item.crop = "Corn"
                    item.trait = this.cornTraits[0]
                    item.gossWilt = "Average"
                    item.greensnap = "Low"
                    item.yieldEnvironment = []
                    item.rate = 20000
                    item.variableRate = false
                    item.rateRange = [20000, 30000]
                    item.avgRate = 20000
                }
                else if (item.crop === "Soybeans") {
                    item.crop = "Soybeans"
                    item.trait = this.soybeanTraits[0]
                    item.idc = "Average"
                    item.cyst = "No"
                    item.phytophthora = "Average"
                    item.whiteMold = "Low"
                    item.population = 140000
                }
                return item
            })
            if (set) {
                this.fields = allFields
            }
            else {
                return allFields
            }
        },
        getFarmMarketData() {
            this.$swal({
                title: '<strong>BEFORE WE GET GOING...</strong>',
                html: '<p>Would you like to request field common land unit boundaries from Farm Market ID or add fields manually?</p>',
                customClass: "farm-market",
                showCancelButton: false,
                confirmButtonColor: '#015a9c',
                cancelButtonColor: '#fff',
                confirmButtonText: 'REQUEST FROM FARM MARKET ID',
                focusCancel: true,
                allowOutsideClick: false
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    let fields = await this.$store.dispatch("plan/getGrowerFields", this.customer.pfs_farmmarketid)
                    await this.$store.dispatch("product/getCRMProducts")
                    this.setFields(fields)
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .farm-market {
        h2 {
            color: #015a9c;
        }
    }
    .farm-market-field {
        h2 {
            font-size: 1.25rem !important;
            padding: .5rem;
            font-weight: normal;
        }
    }
    .new-plan {
        h1 {
            color: #015a9c;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
</style>