<template>
    <v-card
        light
        max-width="500px"
        class="mx-auto new-plan"
    >
        <v-card-title class="primary">New Plan</v-card-title>
        <v-card-text class="mt-5">
            <v-text-field placeholder="Plan Name*" :rules="[rules.required]" v-model="name" autofocus />
            <v-range-slider
                class="py-3"
                :max="2.5"
                :min="0.0"
                :step=".1"
                thumb-label="always"
                label="Soybean Maturity"
                v-model="soybeanMaturity"
                :thumb-size="24"
            >
                <template v-slot:thumb-label="props">
                    {{ props.value === 0 ? '0.0' : props.value }}
                </template>
            </v-range-slider>
            <v-slider
                class="pb-3"
                :max="70"
                :min="30"
                :step="5"
                thumb-label="always"
                :thumb-size="24"
                label="Yield Average"
                v-model="soybeanYield"
            ></v-slider>
            <v-range-slider
                class="pb-3"
                :max="107"
                :min="74"
                :step="1"
                thumb-label="always"
                :thumb-size="24"
                label="Corn Relative Maturity"
                v-model="cornMaturity"
            ></v-range-slider>
            <v-slider
                :max="225"
                :min="125"
                :step="5"
                thumb-label="always"
                label="Yield Average"
                v-model="cornYield"
                :thumb-size="24"
            ></v-slider>
        </v-card-text>
        <v-card-actions class="pt-0 justify-center">
        <v-btn :disabled="!name" @click="setCropValues" class="mb-2" color="primary">Next</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "CropSetup",
    data() {
        return {
            name: "",
            soybeanMaturity: [0.0, .5],
            soybeanYield: 50,
            cornMaturity: [74, 79],
            cornYield: 170,
            rules: {
                required: value => !!value || 'Plan name required.'
            }
        }
    },
    async created() {
    },
    computed: {
        cropValues() {
            return {
                name: this.name,
                soybeanMaturity: this.soybeanMaturity,
                soybeanYield: this.soybeanYield,
                cornMaturity: this.cornMaturity,
                cornYield: this.cornYield
            }
        }
    },
    methods: {
        setCropValues() {
            this.$store.commit("product/setCropValues", this.cropValues)
            this.$emit('next')
        }
    }
}
</script>

<style lang="scss" scoped>
    .new-plan {
        .v-card__title{
            color: #fff;
            text-transform: uppercase;
        }
        ::v-deep .v-label{
            font-size: 1.25rem;
            color: #000;
        }
    }
</style>